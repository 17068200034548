import { createBoilerplateTheme } from "@gisce/oficina-virtual-components";

const customConstants = {
  palette: {
    primary: {
      main: "#E2640E",
    },
    secondary: {
      main: "#D8D204",
    }
  },
};

export default createBoilerplateTheme(customConstants);
